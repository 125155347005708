import React from 'react';

const ApproachIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.0688 16.0548L24.8069 10.0685C24.7245 10.0235 24.6322 10 24.5383 10C24.4445 10 24.3522 10.0235 24.2698 10.0685L13.2922 16.0864C13.2036 16.1338 13.1296 16.2044 13.0781 16.2907C13.0266 16.3769 12.9996 16.4756 13 16.5761V24.4736C13.016 27.6184 13.9427 30.6912 15.668 33.3205C17.3933 35.9498 19.8434 38.023 22.7219 39.2894L24.2461 39.9528C24.3171 39.9839 24.3937 40 24.4712 40C24.5487 40 24.6253 39.9839 24.6963 39.9528L26.3864 39.2342C29.3376 38.0022 31.8594 35.9262 33.6353 33.2666C35.4112 30.607 36.3621 27.4821 36.3689 24.2841V16.5524C36.3674 16.4503 36.3388 16.3504 36.2861 16.263C36.2334 16.1756 36.1584 16.1038 36.0688 16.0548V16.0548ZM35.2395 24.2841C35.2363 27.2578 34.3556 30.1645 32.7076 32.6399C31.0597 35.1152 28.7179 37.0493 25.9757 38.1996V38.1996L24.4831 38.8393L23.18 38.2786C20.484 37.0972 18.1898 35.1571 16.5772 32.6947C14.9646 30.2323 14.1031 27.3539 14.0978 24.4104V16.8841L24.5225 11.1741L35.2474 16.892L35.2395 24.2841Z"
      fill="black"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M20.3684 25.2003C20.2667 25.1026 20.1323 25.0463 19.9914 25.0422C19.8504 25.0381 19.713 25.0865 19.6057 25.178C19.4985 25.2696 19.429 25.3977 19.4109 25.5375C19.3928 25.6773 19.4272 25.8189 19.5076 25.9347L22.485 29.4097C22.5316 29.4671 22.5892 29.5146 22.6544 29.5494C22.7197 29.5841 22.7912 29.6055 22.8648 29.6121C22.9385 29.6187 23.0127 29.6105 23.0831 29.5879C23.1535 29.5654 23.2187 29.5289 23.2747 29.4807L30.1851 23.8577C30.2427 23.8115 30.2906 23.7545 30.3261 23.6898C30.3616 23.6251 30.384 23.5541 30.3921 23.4808C30.4002 23.4074 30.3937 23.3332 30.3731 23.2624C30.3525 23.1916 30.3181 23.1255 30.272 23.0679C30.2258 23.0104 30.1688 22.9624 30.1041 22.9269C30.0395 22.8914 29.9684 22.869 29.8951 22.8609C29.8218 22.8528 29.7476 22.8593 29.6767 22.8799C29.6059 22.9005 29.5398 22.9349 29.4822 22.981L22.9825 28.2329L20.3684 25.2003Z"
      fill="black"
      stroke="black"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ApproachIcon;
