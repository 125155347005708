export enum SIZECARDNODES {
  NORMAL = 'normal',
  SMALL = 'small',
}

export interface ShopCardProps {
  size: CommonTypes.sizeCardNode;
  image: CommonTypes.ResponsiveImage;
  url: string;
  text?: string;
}
