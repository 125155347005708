import React from 'react';

const Phone = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.1917 18.1221C29.1039 16.0344 26.4568 14.9912 24.0036 15.0001L23.9964 13.0001C27.0104 12.9892 30.1615 14.2635 32.6059 16.7079C35.0502 19.1523 36.3246 22.3034 36.3137 25.3174L34.3137 25.3102C34.3226 22.857 33.2794 20.2099 31.1917 18.1221ZM16.3009 16.4019L16.251 16.4499L16.2155 16.4771C15.9022 16.7196 15.5656 17.0377 15.2032 17.4254C14.6731 17.9925 14.0962 18.7003 13.5614 19.4361L13.2808 19.8304C13.0634 20.1413 12.9671 20.521 13.01 20.8979L13.0275 21.0521C13.4074 23.92 15.9043 28.7137 18.3107 31.2496L18.5141 31.4586C20.9976 33.9411 25.9902 36.5808 28.9237 36.9699L29.1147 36.991C29.4815 37.0306 29.8505 36.9387 30.1559 36.7316L30.231 36.6807C30.9906 36.1471 31.7192 35.5727 32.324 35.0319L32.5675 34.8095L32.7342 34.6514C33.0573 34.34 33.3194 34.0563 33.518 33.8002L33.6043 33.6837L33.645 33.6381C33.8457 33.3932 33.9724 33.0869 33.9943 32.7537L33.9966 32.7187C34.0062 32.5742 33.9955 32.4291 33.9649 32.2876C33.6861 30.9971 31.0375 28.5164 29.5809 28.1304L29.4419 28.0982C28.897 27.9772 28.329 28.157 27.953 28.5695L27.8784 28.6515C27.6313 28.9283 27.4309 29.1996 27.1901 29.5727L26.9245 29.9961C26.35 30.923 26.1446 31.0676 25.5476 31.0676C24.604 31.0676 23.8277 30.5945 21.9518 28.7414L21.2359 28.0243C19.3575 26.1159 18.935 25.4007 18.935 24.4563C18.935 23.8823 19.066 23.6842 19.8551 23.1757L20.0783 23.0357C20.6719 22.6693 21.0026 22.4379 21.3404 22.1362L21.4392 22.0449C21.8587 21.655 22.0304 21.0665 21.8862 20.5123L21.8702 20.4509L21.8417 20.3543L21.8139 20.2703L21.7521 20.1053C21.129 18.6006 18.8754 16.3332 17.7516 16.0523L17.5952 16.0214C17.0834 15.9384 16.6273 16.1033 16.3009 16.4019ZM25.0246 20.1704C25.8049 20.2942 26.7828 20.7844 27.6561 21.6577C28.5294 22.5309 29.0195 23.5089 29.1433 24.2892L31.1186 23.9759C30.9161 22.6993 30.1799 21.353 29.0703 20.2434C27.9608 19.1339 26.6145 18.3977 25.3379 18.1951L25.0246 20.1704Z"
      fill="black"
    />
  </svg>
);

export default Phone;
