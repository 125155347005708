import React from 'react';

import { ICONS_SVG } from 'common/IconsSVG';
import AerosolIcon from 'common/IconsSVG/AerosolIcon';
import ApproachIcon from 'common/IconsSVG/ApproachIcon';
import EmailIcon from 'common/IconsSVG/EmailIcon';
import FacebookIcon from 'common/IconsSVG/FacebookIcon';
import Phone from 'common/IconsSVG/Phone';
import TwitterIcon from 'common/IconsSVG/Twitter';
import WhatsApp from 'common/IconsSVG/WhatsApp';

export const getIconSvg = (variant: CommonTypes.IconSvgVariantType) => {
  const iconsMap = {
    [ICONS_SVG.VARIANT.APPROACH]: <ApproachIcon />,
    [ICONS_SVG.VARIANT.AEROSOL]: <AerosolIcon />,
    [ICONS_SVG.VARIANT.TWITTER]: <TwitterIcon />,
    [ICONS_SVG.VARIANT.FACEBOOK]: <FacebookIcon />,
    [ICONS_SVG.VARIANT.EMAIL]: <EmailIcon />,
    [ICONS_SVG.VARIANT.WHATSAPP]: <WhatsApp />,
    [ICONS_SVG.VARIANT.PHONE]: <Phone />,
  };

  return iconsMap[variant];
};
