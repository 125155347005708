import React, { FC } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from 'layout';
import Typography from 'common/Typography';
import { BUTTON } from 'components/Button';
import CarouselArrow from 'components/CarouselArrow';
import ProductCard from 'components/ProductCard';

import { RelatedProductsProps } from './models';

import './RelatedProducts.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const swiperOptions: SwiperOptions = {
  modules: [Navigation, Pagination],
  loop: false,
  navigation: { prevEl: '.arrow-left', nextEl: '.arrow-right' },
  pagination: {
    el: '.swiper-pagination-related-products',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    870: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1140: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
};

const renderCarousel = (products: CommonTypes.ProductCardType[]) => (
  <Swiper className="related-products__slider" {...swiperOptions}>
    {products?.map(
      ({
        productName,
        productText,
        image: { image },
        filterSection,
        button,
      }: CommonTypes.ProductCardType) => (
        <SwiperSlide key={productName} className="related-products__item">
          <ProductCard
            image={image}
            title={productName}
            description={productText}
            filterSection={filterSection}
            button={button}
          />
        </SwiperSlide>
      )
    )}
  </Swiper>
);

const RelatedProducts: FC<RelatedProductsProps> = ({ sectionTitle, products }) => {
  const renderNavigation =
    products.length > 3 ? (
      <>
        <CarouselArrow
          ariaLabel="prev"
          icon={faChevronLeft}
          className="arrow swiper-button-prev arrow-left hide--on-mobile"
          variant={BUTTON.VARIANT.ARROW_CONTAINED}
        />
        <CarouselArrow
          ariaLabel="next"
          icon={faChevronRight}
          className="arrow swiper-button-next arrow-right hide--on-mobile"
          variant={BUTTON.VARIANT.ARROW_CONTAINED}
        />
      </>
    ) : null;

  const renderRelatedProducts = products.length ? (
    <Container fluid noPadding className="related-products">
      <Container>
        <Typography variant="heading2" weight="medium" align="center">
          {sectionTitle}
        </Typography>
        {renderCarousel(products)}
        {renderNavigation}
        <div className="swiper-pagination-related-products hide--on-desktop" />
      </Container>
    </Container>
  ) : null;

  return renderRelatedProducts;
};

export default RelatedProducts;
