import React, { FC } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useScreenService } from 'hooks/useScreenService';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from 'layout';
import RightArrow from 'common/IconsSVG/RightArrow';
import Typography from 'common/Typography';
import { BUTTON } from 'components/Button';
import CarouselArrow from 'components/CarouselArrow';
import ShopCard from 'components/ShopCard';
import { SIZECARDNODES } from 'components/ShopCard/models.d';

import { BuyNowCardsProps } from './models';

import './BuyNowCards.scss';

import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss';

const BuyNowCards: FC<BuyNowCardsProps> = ({ shopList, size, carouselButtons }) => {
  const buyNowClasses = classNames('buy-now-cards', {
    [`buy-now-cards--${size}`]: size,
  });

  const { isMobile } = useScreenService();

  const swiperOptions: SwiperOptions = {
    modules: [Navigation, Pagination],
    navigation: { prevEl: '.prevArrow', nextEl: '.nextArrow' },
    pagination: { el: '.swiper-pagination', clickable: true },
    loop: false,
    centeredSlides: false,
    breakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      510: {
        slidesPerView: 2.5,
      },
      1056: {
        slidesPerView: 5,
      },
    },
  };

  const { prevBtnAria, nextBtnAria } = carouselButtons!;

  const boxText = shopList[0].shopButtonText ? shopList[0].shopButtonText : null;

  const renderBuyCarousel =
    size === SIZECARDNODES.SMALL ? (
      <Container noPadding className="buy-now-cards__slider">
        <CarouselArrow
          icon={faChevronLeft}
          ariaLabel={prevBtnAria}
          variant={BUTTON.VARIANT.ARROW}
          className="prevArrow hide--on-mobile"
        />
        <div className="buy-now-cards__first-card">
          <Typography variant="label" weight="bold">
            {boxText}
          </Typography>
          <RightArrow />
        </div>
        <Swiper {...swiperOptions}>
          {shopList?.map(({ shopButton, shopButtonText, shopButtonLink, shopMobileLink }) => {
            const chooseUrl = isMobile && shopMobileLink ? shopMobileLink : shopButtonLink;
            const { url, queryString } = chooseUrl;
            const joinUrl = queryString ? url.concat(queryString) : url;

            return (
              <SwiperSlide key={shopButton.altText}>
                <ShopCard size={size} image={shopButton} url={joinUrl} text={shopButtonText} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="swiper-pagination hide--on-desktop" />
        <CarouselArrow
          icon={faChevronRight}
          ariaLabel={nextBtnAria}
          variant={BUTTON.VARIANT.ARROW}
          className="nextArrow hide--on-mobile"
        />
      </Container>
    ) : (
      <>
        {shopList?.map(({ shopButton, shopButtonText, shopButtonLink, shopMobileLink }) => {
          const chooseUrl = isMobile && shopMobileLink ? shopMobileLink : shopButtonLink;
          const { url, queryString } = chooseUrl;
          const joinUrl = queryString ? url.concat(queryString) : url;

          return (
            <ShopCard
              key={url}
              size={size}
              image={shopButton}
              url={joinUrl}
              text={shopButtonText}
            />
          );
        })}
      </>
    );

  return (
    <Container fluid className={buyNowClasses}>
      {renderBuyCarousel}
    </Container>
  );
};

export default BuyNowCards;
