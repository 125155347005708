import React, { FC } from 'react';

import Typography from 'common/Typography';

import { TipProps } from './models';

import './Tip.scss';

const Tip: FC<TipProps> = ({ title, description, categories: { name: categoryName } }) => (
  <li className="tip" data-testid="tip">
    <Typography variant="label" component="p" weight="regular" customClass="tip__category">
      {categoryName}
    </Typography>
    <Typography variant="heading5" weight="bold" customClass="tip__title">
      {title}
    </Typography>
    <Typography
      variant="paragraph1"
      weight="regular"
      customClass="tip__description"
      dangerouslySetInnerHTML={description}
    />
  </li>
);

export default Tip;
