import React, { FC, useEffect, useState } from 'react';

import { Container } from 'layout';
import CorrectIcon from 'common/IconsSVG/CorrectIcon';
import Image from 'common/Image';
import Typography from 'common/Typography';
import { getIconSvg } from 'utils/getIconSvg';

import { NewFilterSectionType, ProductIntroProps } from './models';

import './ProductIntro.scss';

const ProductIntro: FC<ProductIntroProps> = ({
  productName,
  productText,
  image,
  filterSection,
  insectSelection,
}) => {
  const [filterSectionData, setFilterSectionData] = useState<NewFilterSectionType[]>([]);

  useEffect(() => {
    const newFilterSection: NewFilterSectionType[][] = filterSection?.map(
      ({ label, protectionPicker, icon }: CommonTypes.FilterSectionType) => {
        const tagsPicker: string[] = protectionPicker.map(({ tags }) => tags!);
        const newSection: NewFilterSectionType[] = tagsPicker?.map((picker: string) => ({
          label,
          picker,
          iconVariant: icon ? icon.iconVariant : icon,
        }));

        return newSection;
      }
    );

    setFilterSectionData(newFilterSection.flat());
  }, [filterSection]);

  const renderFilterSection = filterSectionData?.map(({ label, picker, iconVariant }) => (
    <li key={picker} className="product-intro__filter">
      {getIconSvg(iconVariant)}
      <Typography variant="label" weight="bold" customClass="product-intro__label">
        {label}
      </Typography>
      <Typography variant="label" weight="regular" customClass="product-intro__picker">
        {picker}
      </Typography>
    </li>
  ));

  const renderInsectSelection = insectSelection?.map(({ insectImage }) => (
    <li key={insectImage.altText} className="insect-selection__item">
      <CorrectIcon />
      <Image
        imageData={insectImage}
        alt={insectImage.altText}
        className="insect-selection__insect"
      />
    </li>
  ));

  return (
    <Container fluid element="section" className="product-intro">
      <Container className="product-intro__wrapper">
        <Image imageData={image} alt={image.altText} className="product-intro__image" />
        <article className="product-intro__content">
          <Typography variant="heading2" weight="bold" customClass="product-intro__title">
            {productName}
          </Typography>
          <Typography
            variant="paragraph1"
            weight="regular"
            dangerouslySetInnerHTML={productText}
            customClass="product-intro__text"
          />
          <ul className="product-intro__filter-section">{renderFilterSection}</ul>
          <ul className="product-intro__insect-selection">{renderInsectSelection}</ul>
        </article>
      </Container>
    </Container>
  );
};

export default ProductIntro;
