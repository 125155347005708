import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Typography from 'common/Typography';
import Tip from 'components/Tip';

import { TipCardsProps } from './models';

import './TipCards.scss';

const TipCards: FC<TipCardsProps> = ({ cardsTitle, cards, customClass }) => {
  const tipCardsClassnames = classNames(customClass, 'tip-cards');

  const renderCards = cards?.map(({ cardTitle, cardDescription, cardCategories }) => (
    <Tip
      key={cardTitle}
      title={cardTitle}
      description={cardDescription}
      categories={cardCategories}
    />
  ));

  const renderSectionTitle = cardsTitle ? (
    <Typography customClass="tip-cards__title" variant="heading2" weight="medium" align="center">
      {cardsTitle}
    </Typography>
  ) : null;

  return (
    <Container fluid noPadding className={tipCardsClassnames}>
      <Container element="section" className="tip-cards__wrapper">
        {renderSectionTitle}
        <ul className="tip-cards__list">{renderCards}</ul>
      </Container>
    </Container>
  );
};

export default TipCards;
