import React, { FC } from 'react';
import { graphql } from 'gatsby';
import AccordionList from 'containers/AccordionList';
import BuyNowCards from 'containers/BuyNowCards';
import ProductIntro from 'containers/ProductIntro';
import RelatedProducts from 'containers/RelatedProducts';
import TipCards from 'containers/TipCards';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import { SIZECARDNODES } from 'components/ShopCard/models.d';

import './ProductDetailsPage.scss';

const ProductDetailsPage: FC<{ data: ProductDetailsPageTypes.ProductDetailsPageSiteTypes }> = ({
  data: {
    productDetails: {
      pageName,
      urls,
      seo,
      image: { image },
      productName,
      productText,
      filterSection,
      shop,
      productFaq,
      relatedProducts: { relatedProductsTitle },
      productsTips,
    },
    allInsectDetails: { nodes: insectSelection },
    allProductDetails: { nodes: products },
    homepage: { carouselButtons },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Breadcrumbs />
      <ProductIntro
        productName={productName}
        productText={productText}
        image={image}
        filterSection={filterSection}
        insectSelection={insectSelection}
      />
      <BuyNowCards shopList={shop} size={SIZECARDNODES.SMALL} carouselButtons={carouselButtons} />
      <AccordionList items={productFaq} customClass="product-details-page__accordion" />
      <RelatedProducts
        products={products}
        sectionTitle={relatedProductsTitle}
        customClass="product-details-page__related-products"
      />
      <TipCards cards={productsTips} customClass="product-details-page__tip-cards" />
    </Layout>
  );
};

export const query = graphql`
  query ProductDetailsPageQuery(
    $lang: String
    $url: String
    $insectSelectionTags: [String]
    $relatedProductsLinks: [String]
  ) {
    productDetails(lang: { eq: $lang }, url: { eq: $url }) {
      ...ProductDetailsPageFragment
    }
    allInsectDetails(filter: { tags: { elemMatch: { tags: { in: $insectSelectionTags } } } }) {
      nodes {
        ...InsectDetailFragment
      }
    }
    allProductDetails(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        ...ProductCardFragment
      }
    }
    homepage {
      carouselButtons {
        nextBtnAria
        prevBtnAria
      }
    }
  }
`;

export default ProductDetailsPage;
