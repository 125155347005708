import React, { FC } from 'react';
import { Link } from '@reach/router';

import Image from 'common/Image';
import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';
import { flatTags } from 'utils/flatTags';

import { ProductCardProps } from './models';

import './ProductCard.scss';

const ProductCard: FC<ProductCardProps> = ({
  image,
  title,
  description,
  filterSection,
  button,
}) => {
  const {
    buttonText,
    buttonLink: { url },
    ariaLabel,
  } = button;

  const renderButton = () => {
    if (!button) return null;

    return (
      <Button
        variant={BUTTON.VARIANT.LINK}
        color={BUTTON.COLOR.PRIMARY}
        customClass="product-card__button"
        icon
        ariaLabel={ariaLabel}
        link={url}
      >
        {buttonText}
      </Button>
    );
  };

  const tags = flatTags(filterSection);

  const renderTags = tags?.map((tag) => (
    <Typography key={tag} variant="label" customClass="product-card__tag">
      {tag}
    </Typography>
  ));

  return (
    <article className="product-card" data-testid="product-card">
      <Link to={url}>
        <Image imageData={image} alt={image.altText} className="product-card__image" />
        <Typography variant="heading8" weight="bold" customClass="product-card__title">
          {title}
        </Typography>
      </Link>
      <section className="product-card__tags">{renderTags}</section>
      <Typography
        variant="paragraph1"
        weight="regular"
        customClass="product-card__description"
        dangerouslySetInnerHTML={description}
      />
      {renderButton()}
    </article>
  );
};

export default ProductCard;
