import React from 'react';

const EmailIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37 17.9105V16H13V17.6623L25.0079 26.5714L37 17.9105ZM13 20.1526V34H37V20.3775L24.992 29.05L13 20.1526Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailIcon;
