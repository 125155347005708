import React from 'react';

const TwitterIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9807 18.9902C35.2098 19.3278 34.3786 19.5561 33.5092 19.6581C34.3983 19.1335 35.0805 18.3017 35.4007 17.3109C34.5695 17.7978 33.6483 18.1499 32.6668 18.3394C31.8836 17.5149 30.7642 17 29.5253 17C27.1498 17 25.2225 18.9004 25.2225 21.2439C25.2225 21.5766 25.2582 21.8996 25.3334 22.2105C21.7559 22.0332 18.5848 20.3454 16.4605 17.7759C16.0899 18.4049 15.878 19.1335 15.878 19.9106C15.878 21.3824 16.6379 22.6816 17.793 23.443C17.0874 23.4224 16.4236 23.2293 15.8423 22.9136V22.9658C15.8423 25.0228 17.3263 26.7386 19.2966 27.1271C18.9346 27.2267 18.5553 27.2765 18.1612 27.2765C17.8841 27.2765 17.6132 27.251 17.3521 27.2012C17.8989 28.8866 19.4887 30.1155 21.3729 30.1471C19.8988 31.2861 18.043 31.9649 16.0271 31.9649C15.6798 31.9649 15.3362 31.9454 15 31.9066C16.9051 33.1087 19.1673 33.813 21.5983 33.813C29.5167 33.813 33.8454 27.3457 33.8454 21.7369C33.8454 21.5524 33.8417 21.3678 33.833 21.1881C34.6741 20.5882 35.4056 19.8414 35.9807 18.9902Z"
      fill="currentColor"
    />
  </svg>
);

export default TwitterIcon;
