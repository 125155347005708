import React, { FC } from 'react';

import Icon from 'common/Icon';
import Button from 'components/Button';

import { CarouselArrowProps } from './models';

import './CarouselArrow.scss';

const CarouselArrow: FC<CarouselArrowProps> = ({ ariaLabel, className, icon, variant }) => (
  <Button customClass={className} variant={variant} ariaLabel={ariaLabel}>
    <Icon icon={icon} />
  </Button>
);

export default CarouselArrow;
