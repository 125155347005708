import React, { FC } from 'react';
import classNames from 'classnames';

import RightArrow from 'common/IconsSVG/RightArrow';
import Image from 'common/Image';
import Link from 'common/Link';
import Typography from 'common/Typography';

import { ShopCardProps, SIZECARDNODES } from './models.d';

import './ShopCard.scss';

const ShopCard: FC<ShopCardProps> = ({ size, image, url, text }) => {
  const shopCardClasses = classNames('shop-card', {
    [`shop-card--${size}`]: size,
  });

  const linkContent =
    size === SIZECARDNODES.NORMAL ? (
      <Typography variant="heading6" weight="bold" customClass="color--red">
        <RightArrow />
        {text || null}
      </Typography>
    ) : null;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link url={url} key={url} customClass={shopCardClasses}>
      <Image imageData={image} alt={image.altText} className="shop-card__image" />
      {linkContent}
    </Link>
  );
};

export default ShopCard;
