import React from 'react';

const AerosolIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.3065 16.5546C27.6153 16.5546 27.8657 16.3026 27.8657 15.9916C27.8657 15.6807 27.6153 15.4286 27.3065 15.4286C26.9977 15.4286 26.7473 15.6807 26.7473 15.9916C26.7473 16.3026 26.9977 16.5546 27.3065 16.5546Z"
      fill="black"
    />
    <path
      d="M30.6616 16.5546C30.9704 16.5546 31.2208 16.3026 31.2208 15.9916C31.2208 15.6807 30.9704 15.4286 30.6616 15.4286C30.3528 15.4286 30.1024 15.6807 30.1024 15.9916C30.1024 16.3026 30.3528 16.5546 30.6616 16.5546Z"
      fill="black"
    />
    <path
      d="M28.9841 15.4285C29.2929 15.4285 29.5432 15.1765 29.5432 14.8655C29.5432 14.5546 29.2929 14.3025 28.9841 14.3025C28.6752 14.3025 28.4249 14.5546 28.4249 14.8655C28.4249 15.1765 28.6752 15.4285 28.9841 15.4285Z"
      fill="black"
    />
    <path
      d="M30.6616 14.3024C30.9704 14.3024 31.2208 14.0504 31.2208 13.7394C31.2208 13.4285 30.9704 13.1764 30.6616 13.1764C30.3528 13.1764 30.1024 13.4285 30.1024 13.7394C30.1024 14.0504 30.3528 14.3024 30.6616 14.3024Z"
      fill="black"
    />
    <path
      d="M30.6616 18.8067C30.9704 18.8067 31.2208 18.5546 31.2208 18.2437C31.2208 17.9327 30.9704 17.6807 30.6616 17.6807C30.3528 17.6807 30.1024 17.9327 30.1024 18.2437C30.1024 18.5546 30.3528 18.8067 30.6616 18.8067Z"
      fill="black"
    />
    <path
      d="M34.0167 16.5546C34.3255 16.5546 34.5759 16.3026 34.5759 15.9916C34.5759 15.6807 34.3255 15.4286 34.0167 15.4286C33.7079 15.4286 33.4575 15.6807 33.4575 15.9916C33.4575 16.3026 33.7079 16.5546 34.0167 16.5546Z"
      fill="black"
    />
    <path
      d="M34.0167 14.3024C34.3255 14.3024 34.5759 14.0504 34.5759 13.7394C34.5759 13.4285 34.3255 13.1764 34.0167 13.1764C33.7079 13.1764 33.4575 13.4285 33.4575 13.7394C33.4575 14.0504 33.7079 14.3024 34.0167 14.3024Z"
      fill="black"
    />
    <path
      d="M34.0167 12.0504C34.3255 12.0504 34.5759 11.7983 34.5759 11.4873C34.5759 11.1764 34.3255 10.9243 34.0167 10.9243C33.7079 10.9243 33.4575 11.1764 33.4575 11.4873C33.4575 11.7983 33.7079 12.0504 34.0167 12.0504Z"
      fill="black"
    />
    <path
      d="M34.0167 21.0588C34.3255 21.0588 34.5759 20.8067 34.5759 20.4958C34.5759 20.1848 34.3255 19.9327 34.0167 19.9327C33.7079 19.9327 33.4575 20.1848 33.4575 20.4958C33.4575 20.8067 33.7079 21.0588 34.0167 21.0588Z"
      fill="black"
    />
    <path
      d="M34.0167 18.8067C34.3255 18.8067 34.5759 18.5546 34.5759 18.2437C34.5759 17.9327 34.3255 17.6807 34.0167 17.6807C33.7079 17.6807 33.4575 17.9327 33.4575 18.2437C33.4575 18.5546 33.7079 18.8067 34.0167 18.8067Z"
      fill="black"
    />
    <path
      d="M28.9841 17.6806C29.2929 17.6806 29.5432 17.4285 29.5432 17.1176C29.5432 16.8066 29.2929 16.5546 28.9841 16.5546C28.6752 16.5546 28.4249 16.8066 28.4249 17.1176C28.4249 17.4285 28.6752 17.6806 28.9841 17.6806Z"
      fill="black"
    />
    <path
      d="M32.3391 15.4285C32.648 15.4285 32.8983 15.1765 32.8983 14.8655C32.8983 14.5546 32.648 14.3025 32.3391 14.3025C32.0303 14.3025 31.78 14.5546 31.78 14.8655C31.78 15.1765 32.0303 15.4285 32.3391 15.4285Z"
      fill="black"
    />
    <path
      d="M32.3391 13.1765C32.648 13.1765 32.8983 12.9244 32.8983 12.6134C32.8983 12.3025 32.648 12.0504 32.3391 12.0504C32.0303 12.0504 31.78 12.3025 31.78 12.6134C31.78 12.9244 32.0303 13.1765 32.3391 13.1765Z"
      fill="black"
    />
    <path
      d="M32.3391 17.6806C32.648 17.6806 32.8983 17.4285 32.8983 17.1176C32.8983 16.8066 32.648 16.5546 32.3391 16.5546C32.0303 16.5546 31.78 16.8066 31.78 17.1176C31.78 17.4285 32.0303 17.6806 32.3391 17.6806Z"
      fill="black"
    />
    <path
      d="M32.3391 19.9328C32.648 19.9328 32.8983 19.6807 32.8983 19.3698C32.8983 19.0588 32.648 18.8068 32.3391 18.8068C32.0303 18.8068 31.78 19.0588 31.78 19.3698C31.78 19.6807 32.0303 19.9328 32.3391 19.9328Z"
      fill="black"
    />
    <path
      d="M21.1555 17.6807V14.3025H24.5106V17.6807"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M18.3877 20.5126L18.4995 19.9496C18.6264 19.3231 18.9613 18.7588 19.4492 18.3494C19.937 17.9401 20.5487 17.7102 21.1836 17.6975H24.4716C25.1113 17.7038 25.7295 17.9309 26.2231 18.3407C26.7166 18.7506 27.0557 19.3185 27.1836 19.9496L27.3346 20.5126"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M27.3346 20.5127C27.4829 20.5127 27.6251 20.572 27.73 20.6776C27.8348 20.7832 27.8937 20.9264 27.8937 21.0757V37.4824C27.8939 37.6309 27.8648 37.7781 27.8082 37.9153C27.7515 38.0525 27.6685 38.1771 27.5638 38.2819L27.0997 38.7435C26.8909 38.955 26.6074 39.0745 26.3113 39.0757H19.383C19.2355 39.0758 19.0893 39.0465 18.953 38.9895C18.8168 38.9325 18.693 38.8489 18.589 38.7435L18.1249 38.2819C17.9257 38.0648 17.8193 37.7777 17.8285 37.4824V21.0757C17.8285 20.9264 17.8874 20.7832 17.9923 20.6776C18.0972 20.572 18.2394 20.5127 18.3877 20.5127H27.3346Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M20.0372 23.3109V25.563" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M20.0372 26.6891V36.2606" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M24.5106 15.9916H23.3923" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
);

export default AerosolIcon;
