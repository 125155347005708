import React from 'react';

const WhatsApp = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="svg_1"
      fill="black"
      d="m37.41737,12.53275c-3.2734,-3.28125 -7.6328,-5.08594 -12.2656,-5.08594c-9.56251,0 -17.34376,7.78125 -17.34376,17.3438c0,3.0546 0.79687,6.039 2.3125,8.6718l-2.46094,8.9844l9.19531,-2.4141c2.53129,1.3829 5.38279,2.1094 8.28909,2.1094l0.0078,0c9.5547,0 17.5078,-7.7812 17.5078,-17.3437c0,-4.6328 -1.9688,-8.98441 -5.2422,-12.26566zm-12.2656,26.68746c-2.5938,0 -5.1328,-0.6953 -7.3438,-2.0078l-0.5234,-0.3125l-5.45312,1.4297l1.45312,-5.3203l-0.34375,-0.5469c-1.44531,-2.2968 -2.20313,-4.9453 -2.20313,-7.6718c0,-7.94536 6.46876,-14.41411 14.42188,-14.41411c3.8516,0 7.4688,1.5 10.1875,4.22656c2.7187,2.72656 4.3906,6.34375 4.3828,10.19535c0,7.9531 -6.6328,14.4218 -14.5781,14.4218zm7.9062,-10.7968c-0.4296,-0.2188 -2.5625,-1.2657 -2.9609,-1.4063c-0.3984,-0.1484 -0.6875,-0.2187 -0.9766,0.2188c-0.289,0.4375 -1.1171,1.4062 -1.375,1.7031c-0.25,0.289 -0.5078,0.3281 -0.9375,0.1094c-2.5468,-1.2735 -4.2187,-2.2735 -5.8984,-5.1563c-0.4453,-0.7656 0.4453,-0.7109 1.2734,-2.3672c0.1407,-0.289 0.0703,-0.539 -0.039,-0.7578c-0.1094,-0.2187 -0.9766,-2.3515 -1.336,-3.2187c-0.3515,-0.84379 -0.7109,-0.7266 -0.9765,-0.74222c-0.25,-0.01563 -0.5391,-0.01563 -0.8281,-0.01563c-0.2891,0 -0.7579,0.10937 -1.1563,0.53906c-0.39844,0.43749 -1.51562,1.48439 -1.51562,3.61719c0,2.1328 1.55472,4.1953 1.76562,4.4844c0.2187,0.289 3.0547,4.664 7.4063,6.5468c2.75,1.1876 3.8281,1.2891 5.2031,1.086c0.8359,-0.125 2.5625,-1.0469 2.9219,-2.0625c0.3593,-1.0156 0.3593,-1.8828 0.25,-2.0625c-0.1016,-0.1953 -0.3907,-0.3047 -0.8204,-0.5156z"
    />
  </svg>
);

export default WhatsApp;
