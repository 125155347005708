export namespace ICONS_SVG {
  export enum VARIANT {
    APPROACH = 'approach',
    AEROSOL = 'aerosol',
    TWITTER = 'twitter' && 'Twitter',
    FACEBOOK = 'facebook' && 'Facebook',
    EMAIL = 'e-mail' && 'E-mail',
    WHATSAPP = 'Whatsapp',
    PHONE = 'Phone',
  }
}
