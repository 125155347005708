import React from 'react';

const CorrectIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25.4997" cy="24.5" r="11.9118" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5296 27.4916L22.5296 27.4916L23.6751 28.6371L23.6751 28.6371L23.6751 28.6371L24.8206 27.4916L24.8206 27.4916L30.9867 21.3255L29.8411 20.18L23.6751 26.3461L21.2455 23.9165L20.1 25.062L22.5296 27.4916Z"
      fill="white"
    />
  </svg>
);

export default CorrectIcon;
